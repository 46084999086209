.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo{
  font-size: 28px;
  font-weight: 600;
  letter-spacing:2px
}
  .editor {
    counter-reset: line;
    border: 1px solid #ebecec;
  }

  .editor #codeArea {
    outline: none;
    padding-left: 60px !important;
  }

  .editor pre {
    padding-left: 60px !important;
  }

  .editor .editorLineNumber {
    position: absolute;
    left: 0px;
    color: #cccccc;
    text-align: right;
    width: 40px;
    font-weight: 100;
  }

  .close {
      color: #fff;
      text-shadow: 0 1px 0 #000;
  }


.spinner {
  border: 4px solid #7ca4c1;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border-left-color: #fff;
  animation: spin 1s ease infinite;
}

.spinner.spinner-sm {
  border: 3px solid #ebecec;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border-left-color:#1672e8;
  animation: spin 1s ease infinite;
}

.spinner.spinner-lg {
  border: 10px solid #ebecec;
  width: 86px;
  height: 86px;
  border-radius: 50%;
  border-left-color: #1572E8;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.icon-cat{
  font-size: 20px;
  cursor:pointer;
  margin:0 10px;
  color: #2A2F5B!important;
  text-decoration: none!important;
}
.icon-cat:hover{
  color: #1572e8 !important;
}

.params{
  background: #f8fbfd;
    border: 1px solid #ebecec;
    border-radius: 10px;
    margin: 0 2px;
    padding: 0 10px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(248 251 253);
}



.nav-collapse > li > .active, .nav-collapse > li > .active:hover, .nav-collapse > li > .active:focus, .nav-collapse > li > .active:active {
    background: #1572E8 !important;
    box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 10%), 4px 4px 15px -5px rgb(21 114 232 / 40%)!important;
    color:#fff!important;
    font-weight: 600!important;
    opacity: 1!important;
}


.btn-disabled{
  border: 1px solid #ebecec!important;
  color: #ebecec!important;
}


.alert{
  min-width: 500px;
}
.alert-danger, .alert-success{
    position: absolute;
    z-index: 10000;
    top: 8px;
    right: 8px;
}

.alert-danger .fa, .alert-danger h4{
  color: #f15860;
  opacity: 1;
}

.alert-success .fa{
  color: #31ce35;
  opacity: 1;
}

.alert .close{
  color:#000;
}